const alertSetup = () => {
  if (!sessionStorage.getItem("close-alert")) {
    sessionStorage.setItem("close-alert", "0");
  }

  if (sessionStorage.getItem("close-alert") === "0") {
    document.getElementById("alert-bar") &&
      document.getElementById("alert-bar").classList.remove("hidden");
  }

  document.getElementById("close-alert")?.addEventListener("click", () => {
    document.getElementById("alert-bar") &&
      document.getElementById("alert-bar").classList.add("hidden");
      sessionStorage.setItem("close-alert", "1");
  });
};

export default alertSetup;
