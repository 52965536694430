const controls = document.getElementsByClassName("accordionControls");
const contentContainer = document.getElementsByClassName("hiddenAccordionArea");

const removeOpenClass = (element) => element.classList.remove("open");
const removeFocusedClass = (element) => element.classList.remove("focused");

export const removeOpenAndFocused = () => {
  [...controls].forEach(removeFocusedClass);
  [...contentContainer].forEach(removeOpenClass);
};

export const escapeKeyboardButtonFunctionality = () =>
  // On escape, remove open class
  document.addEventListener(
    "keyup",
    function (e) {
      if (e.key === "Escape") {
        removeOpenAndFocused();
      }
    },
    false
  );

const accordionSetup = () => {
  // Set up keyboard functionality
  escapeKeyboardButtonFunctionality();

  // Find the controllers
  [...controls].forEach((control) => {
    const controlId = control.getAttribute("id");
    const specificContentContainer = document.getElementById(
      `container-${controlId}-container`
    );
    const urlHash = window.location.hash;

    // Add open and focused classes if directly routed to
    if (
      control.children[1] &&
      urlHash ===
        `#${control.children[1].textContent
          .toLowerCase()
          .trim()
          .replace(/[^a-zA-Z0-9 ]/g, "")
          .replace(/\s+/g, "-")}`
    ) {
      removeOpenAndFocused();
      control.classList.add("focused");
      specificContentContainer.classList.add("open");
    }

    ["click", "Enter"].forEach((eventType) =>
      control.addEventListener(
        eventType,
        function (e) {
          e.stopPropagation();

          // Clicking control toggles class
          if ([...control.classList].includes("focused")) {
            // Add focused state to controller and open state content area
            removeFocusedClass(document.getElementById(controlId));
            removeOpenClass(specificContentContainer);

            return;
          }

          // Make sure its a click or enter press
          if (eventType !== "keyup" || e.key === "Enter") {
            // Add focused state to controller and open state content area
            document.getElementById(controlId).classList.add("focused");
            specificContentContainer.classList.add("open");
          }
        },
        true
      )
    );
  });
};

export default accordionSetup;
