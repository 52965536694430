import barba from "@barba/core";
import { gsap } from "gsap";
import $ from "jquery";
import "@accessible360/accessible-slick";

import carouselSetup from "./carousel";
import expandingContainerSetup, { removeOpenAndFocused } from "./expandingText";
import accordionSetup from "./accordion";
import hamburgerSetup from "./hamburger";
import expandingCarouselSetup from "./expandingCarousel";
import translateSetup from "./translate";
import alertSetup from "./alertBar";
import { setupModal, closeModal, modalOpenClass } from "./modal";

const runOnPageChangeAndFirstLoad = () => {
  accordionSetup();
  carouselSetup();
  expandingCarouselSetup();
  setupModal();
};

const genericTransition = {
  // Before current component leaves, set next to not take up dom space
  before(data) {
    gsap.set(data.next.container, {
      opacity: 0,
      position: "absolute",
      force3D: false,
      ease: "power2.out",
    });
  },

  // Once next container enters, animate it to opacity 1
  enter(data) {
    gsap.set(data.next.container, {
      position: "relative",
    });

    return new Promise((resolve) => {
      gsap.to(data.current.container, {
        opacity: 0,
        duration: 0.25,
        force3D: false,
        ease: "power2.out",
        onComplete: () => resolve(),
      });
    });
  },

  after(data) {
    return new Promise((resolve) => {
      gsap.to(data.next.container, {
        opacity: 1,
        duration: 0.25,
        ease: "power2.out",
        force3D: false,
        onComplete: () => {
          resolve();
        },
      });
    });
  },
};

// Hooks for page transitions
barba.init({
  prevent: ({ el, href }) => href && href.includes("sign-up"),
  prefetchIgnore: true,
  transitions: [
    // Same route animation
    {
      name: "self",
      sync: true,
      ...genericTransition,
    },
    // Default pages
    {
      name: "default-transition",
      sync: true,
      ...genericTransition,
    },
  ],
});

barba.hooks.beforeLeave(() => {
  removeOpenAndFocused();
  window.scroll(0, 0);
});

barba.hooks.after(() => {
  runOnPageChangeAndFirstLoad();
  document.body.classList.contains(modalOpenClass) && closeModal();

  const nextRoute = barba.history.current?.url?.split("#")?.[1];

  nextRoute
    ? document.getElementById(nextRoute)?.scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "start",
      })
    : undefined; //do nothing as we already scrolled to (0,0) in the beforeLeave hook;
});

$(() => {
  runOnPageChangeAndFirstLoad();
  hamburgerSetup();
  alertSetup();
  expandingContainerSetup();
  translateSetup();

  const nextRoute = barba.history.current?.url?.split("#")?.[1];

  nextRoute
    ? document.getElementById(nextRoute)?.scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "start",
      })
    : undefined; //do nothing as we already scrolled to (0,0) in the beforeLeave hook;
});
