import $ from "jquery";

const translateSetup = () => {

  $(".translate").on("click", function (e) {
    $("div#google_translate_element").toggleClass("open");
    $("div#google_translate_element").focus();
  });
  
  $(window).scroll(function () {
    $("div#google_translate_element").removeClass("open");
  });
  
};

export default translateSetup;