import $ from "jquery";

const nextArrowSVG = `<svg class='next-svg' xmlns="http://www.w3.org/2000/svg" width="78" height="78" viewBox="0 0 78 78"><defs><style>.a{fill:#fff;}.a,.b{stroke:#0071b6;stroke-width:4px;}.b,.d{fill:none;}.c{stroke:none;}</style></defs><g transform="translate(697 4702) rotate(180)"><g class="a" transform="translate(619 4624)"><circle class="c" cx="39" cy="39" r="39"/><circle class="d" cx="39" cy="39" r="37"/></g><path class="b" d="M15834.658,1364.414l21.842-21.842,21.842,21.842" transform="translate(136.228 20568.605) rotate(-93)"/></g></svg>`;

const prevArrowSVG = `<svg class='prev-svg' xmlns="http://www.w3.org/2000/svg" width="78" height="78" viewBox="0 0 78 78"><defs><style>.a{fill:#fff;}.a,.b{stroke:#0071b6;stroke-width:4px;}.b,.d{fill:none;}.c{stroke:none;}</style></defs><g transform="translate(-619 -4624)"><g class="a" transform="translate(619 4624)"><circle class="c" cx="39" cy="39" r="39"/><circle class="d" cx="39" cy="39" r="37"/></g><path class="b" d="M15834.658,1364.414l21.842-21.842,21.842,21.842" transform="translate(136.228 20568.605) rotate(-93)"/></g></svg>`;

const carouselSetup = () => {
  $(".carousel").slick({
    nextArrow: `<button type='button' title='next' class='slick-next'>${nextArrowSVG}</button>`,
    prevArrow: `<button type='button' title='previous' class='slick-prev'>${prevArrowSVG}</button>`,
    slidesToShow: 3,
    variableWidth: true,
    autoplay: false,
    arrows: false,
    centerMode: false,
    responsive: [
      // Mobile
      {
        breakpoint: 816, // Match css variable (small breakpoint + 1px)
        settings: {
          arrows: false,
          dots: false,
          slidesToShow: 1,
        },
      },
    ],
  });

  // Once carousel is initialized, remove class so it doesn't initialize again
  $(".carousel").removeClass("carousel");

  $(".hero-carousel").slick({
    slidesToShow: 1,
    variableWidth: false,
    autoplay: true,
    centerMode: false,
    arrows: false,
    fade: true,
    cssEase: 'linear',
    responsive: [
      // Mobile
      {
        breakpoint: 816, // Match css variable (small breakpoint + 1px)
        settings: {
          arrows: false,
          dots: false,
          slidesToShow: 1,
        },
      },
    ],
  });

  // Once carousel is initialized, remove class so it doesn't initialize again
  $(".hero-carousel").removeClass("hero-carousel");


  $(".visit-carousel").slick({
    infinite: false,
    nextArrow: `<button type='button' title='next' class='slick-next'>${nextArrowSVG}</button>`,
    prevArrow: `<button type='button' title='previous' class='slick-prev'>${prevArrowSVG}</button>`,
    slidesToShow: 2,
    variableWidth: true,
    adaptiveHeight: false,
    centerMode: false,
    autoplay: false,
    arrows: true,
    responsive: [
      // Mobile
      {
        breakpoint: 816, // Match css variable (small breakpoint + 1px)
        settings: {
          arrows: true,
          dots: false,
          slidesToShow: 1,
          centerMode: false,
        },
      },
    ],
  });

  // Once carousel is initialized, remove class so it doesn't initialize again
  $(".visit-carousel").removeClass("visit-carousel");

  $(".person-carousel").slick({
    infinite: false,
    nextArrow: `<button type='button' title='next' class='slick-next'>${nextArrowSVG}</button>`,
    prevArrow: `<button type='button' title='previous' class='slick-prev'>${prevArrowSVG}</button>`,
    slidesToShow: 3,
    // variableWidth: true,
    // variableHeight: true,
    // centerMode: true,
    autoplay: false,
    arrows: true,
    responsive: [
      // Mobile
      {
        breakpoint: 420, // Match css variable (small breakpoint + 1px)
        settings: {
          arrows: true,
          dots: false,
          slidesToShow: 1,
          centerMode: true,
        },
      },
    ],
  });

  // Once carousel is initialized, remove class so it doesn't initialize again
  $(".person-carousel").removeClass("person-carousel");
};

export default carouselSetup;