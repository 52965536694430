import $ from "jquery";

// FIXME: Only click on nav items, not enter click

let myHamburger = document.getElementById("hamburger"),
  myNavMenu = document.getElementById("navMenu"),
  closeMenuButton = document.getElementById("closeNavMenu"),
  body = document.querySelector("body"),
  controls = document.getElementsByClassName("hamburger-control"),
  details = document.getElementsByClassName("detail"),
  hamburgerClosers = document.getElementsByClassName("hamburger-closer"),
  divider = document.getElementById("mobile-hr"),
  alertBar = document.getElementById("alert-bar"),
  translate = [...document.getElementsByClassName("translate-container")][0];

function doMenuOpen() {
  myHamburger.setAttribute("aria-expanded", true);
  myNavMenu.classList.remove("vh");
  myNavMenu.classList.remove("hidden");
  body.classList.add("menuOpen");
  myHamburger.classList.add("menuOpen");
  closeMenuButton.focus();
  [...details].forEach((detail) => detail.classList.add("hidden"));
  [...details].forEach((detail) => detail.classList.add("vh"));
  alertBar && alertBar.classList.add("hide-alert");
  translate.classList.add("hide-translate");
}

function doMenuClose() {
  myHamburger.setAttribute("aria-expanded", false);
  myNavMenu.classList.add("hidden");
  myNavMenu.classList.add("vh");
  body.classList.remove("menuOpen");
  myHamburger.classList.remove("menuOpen");
  myHamburger.focus();
  [...controls].forEach((control) => control.classList.remove("hidden"));
  [...controls].forEach((control) => control.classList.remove("vh"));
  document
    .getElementsByClassName("hamburger-providers")[0]
    .classList.remove("hidden");
  document
    .getElementsByClassName("hamburger-providers")[0]
    .classList.remove("vh");
  divider.classList.remove("hidden");
  divider.classList.remove("vh");
  [...details].forEach((detail) => detail.classList.add("hidden"));
  [...details].forEach((detail) => detail.classList.add("vh"));
  alertBar && alertBar.classList.remove("hide-alert");
  translate.classList.remove("hide-translate");
}

const hamburgerSetup = () => {
  document.addEventListener(
    "keyup",
    function (e) {
      if (e.key === "Escape") {
        doMenuClose();
      }
    },
    false
  );

  $(".hamburger").on("click", function (event) {
    doMenuOpen();
  });

  [...controls].forEach((item) =>
    item.addEventListener(
      "click",
      function () {
        // Hide container
        [...controls].forEach((control) => control.classList.add("hidden"));
        [...controls].forEach((control) => control.classList.add("vh"));
        document
          .getElementsByClassName("hamburger-providers")[0]
          .classList.add("hidden");
        document
          .getElementsByClassName("hamburger-providers")[0]
          .classList.add("vh");
        divider.classList.add("hidden");
        divider.classList.add("vh");

        // show detailed view
        const matchingDetail = `${item.getAttribute("id")}-detail`;
        document.getElementById(matchingDetail).classList.remove("hidden");
        document.getElementById(matchingDetail).classList.remove("vh");

        // Assign button click function
        $(".mobile-back").on("click", function (event) {
          // show container
          [...controls].forEach((control) =>
            control.classList.remove("hidden")
          );
          [...controls].forEach((control) => control.classList.remove("vh"));
          document
            .getElementsByClassName("hamburger-providers")[0]
            .classList.remove("vh");
          document
            .getElementsByClassName("hamburger-providers")[0]
            .classList.remove("hidden");
          divider.classList.remove("hidden");
          divider.classList.remove("vh");

          // hide detailed view
          [...details].forEach((detail) => detail.classList.add("hidden"));
          [...details].forEach((detail) => detail.classList.add("vh"));
        });
      },
      false
    )
  );

  [...hamburgerClosers].forEach((closer) =>
    closer.addEventListener(
      "click",
      function () {
        doMenuClose();
      },
      false
    )
  );
};

export default hamburgerSetup;
