const controls = document.getElementsByClassName("expandControl");
const contentContainer = document.getElementsByClassName("nav-card");
const hiddenArea = document.getElementsByClassName("hiddenArea");

const removeOpenClass = (element) => element.classList.remove("open");
const removeFocusedClass = (element) => element.classList.remove("focused");

export const removeOpenAndFocused = () => {
  [...controls].forEach(removeFocusedClass);
  [...hiddenArea].forEach(removeOpenClass);
};

const escapeKeyboardButtonFunctionality = () =>
  // On escape, remove open class
  document.addEventListener(
    "keyup",
    function (e) {
      if (e.key === "Escape") {
        removeOpenAndFocused();
      }
    },
    false
  );

const expandingContainerSetup = () => {
  escapeKeyboardButtonFunctionality();

  // Find the controllers for mouseover and enter
  [...controls].forEach((control) => {
    ["mouseover", "keyup"].forEach((eventType) => {
      control.addEventListener(
        eventType,
        function (e) {
          e.preventDefault();
          // Clicking control toggles class
          // if ([...control.classList].includes("focused")) {
          //   removeOpenAndFocused();
          //   return;
          // }

          // Make sure its a click or enter press
          // if (eventType !== "keyup" || e.key === "Enter") {
          const controlId = control.getAttribute("id");
          const specificContentContainer = document.querySelector(
            `#container-${controlId}-container`
          );
          if (
            e.relatedTarget?.id === specificContentContainer?.id ||
            e.relatedTarget?.parentElements?.id === specificContentContainer?.id
          ) {
            return;
          }

          removeOpenAndFocused();

          // Add focused state to controller and open state content area
          document.getElementById(controlId).classList.add("focused");
          specificContentContainer.classList.add("open");

          // If user clicks document and the event does not associate with the control, close the content area
          document.addEventListener(
            "click",
            function (event) {
              if (
                !control.contains(event.target) &&
                !specificContentContainer.contains(event.target) &&
                e.key !== "Enter"
              ) {
                removeOpenClass(specificContentContainer);
                removeFocusedClass(control);
              }
            },
            true
          );
        },
        // },
        true
      );
    });
  });

  // Find the controllers for mouseleave
  [...controls].forEach((control) => {
    control.addEventListener(
      "mouseleave",
      (e) => {
        const controlId = control.getAttribute("id");
        const specificContentContainer = document.querySelector(
          `#container-${controlId}-container`
        );
        if (
          e.relatedTarget &&
          (e.relatedTarget?.id === specificContentContainer?.id ||
            e.relatedTarget?.parentElement?.id ===
              specificContentContainer?.id ||
            e.relatedTarget.innerText === control.innerText)
        ) {
          return;
        } else {
          removeOpenAndFocused();
        }
      },
      true
    );
  });

    // Find the containers for mouseleave
  [...contentContainer].forEach((container) => {
    container.addEventListener(
      "mouseleave",
      (e) => {
        e.preventDefault();

        if (
          e.relatedTarget.classList.contains("nav-card") ||
          e.relatedTarget.classList.contains("navControls")
        ) {
          return;
        }

        removeOpenAndFocused();
      },
      true
    );
  });
};

export default expandingContainerSetup;
