import $ from "jquery";
const nextArrowSVG = `<svg class='next-svg' xmlns="http://www.w3.org/2000/svg" width="78" height="78" viewBox="0 0 78 78"><defs><style>.a{fill:#fff;}.a,.b{stroke:#0071b6;stroke-width:4px;}.b,.d{fill:none;}.c{stroke:none;}</style></defs><g transform="translate(697 4702) rotate(180)"><g class="a" transform="translate(619 4624)"><circle class="c" cx="39" cy="39" r="39"/><circle class="d" cx="39" cy="39" r="37"/></g><path class="b" d="M15834.658,1364.414l21.842-21.842,21.842,21.842" transform="translate(136.228 20568.605) rotate(-93)"/></g></svg>`;

const prevArrowSVG = `<svg  class='prev-svg' xmlns="http://www.w3.org/2000/svg" width="78" height="78" viewBox="0 0 78 78"><defs><style>.a{fill:#fff;}.a,.b{stroke:#0071b6;stroke-width:4px;}.b,.d{fill:none;}.c{stroke:none;}</style></defs><g transform="translate(-619 -4624)"><g class="a" transform="translate(619 4624)"><circle class="c" cx="39" cy="39" r="39"/><circle class="d" cx="39" cy="39" r="37"/></g><path class="b" d="M15834.658,1364.414l21.842-21.842,21.842,21.842" transform="translate(136.228 20568.605) rotate(-93)"/></g></svg>`;

// TODO: Fix height issue of images in carousel (Small gap below images)
const expandingCarouselSetup = () => {
  $(".hidden-carousel").slick({
    infinite: false,
    nextArrow: `<button type='button' title='next' class='slick-next item-next'>${nextArrowSVG}</button>`,
    prevArrow: `<button type='button' title='previous' class='slick-prev item-prev'>${prevArrowSVG}</button>`,
    slidesToShow: 1,
    variableWidth: true,
    autoplay: false,
    arrows: true,
    centerMode: true,
    regionLabel: "Main image carousel",
    asNavFor: ".secondary-carousel",
    instructionsText:
      "Changing this current slide of this carousel will change the current slide of the thumbnail carousel that follows.",
    responsive: [
      // Mobile
      {
        breakpoint: 816, // Match css variable (small breakpoint + 1px)
        settings: {
          arrows: true,
          dots: false,
          slidesToShow: 1,
          nextArrow: `<button type='button' title='next' class='slick-next item-next mobile'>${nextArrowSVG}</button>`,
          prevArrow: `<button type='button' title='previous' class='slick-prev item-prev mobile'>${prevArrowSVG}</button>`,
        },
      },
    ],
  });

  $('.hidden-carousel').removeClass('hidden-carousel')
  
  $(".carousel-for-expand").slick({
    infinite: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    fade: true,
    asNavFor: ".main-carousel",
    instructionsText:
    "Changing the current slide of this carousel will change the current slide of the preceding main image carousel.",
    regionLabel: "Corresponding article carousel",
    adaptiveHeight: true,
  });

  $('.carousel-for-expand').removeClass('carousel-for-expand')
};

export default expandingCarouselSetup;
