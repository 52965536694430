const modals = document.getElementsByClassName("modal");
const overlays = document.getElementsByClassName("modal-overlay");
const openModalBtns = document.getElementsByClassName("person-modal-activator");
const closeModalBtns = document.getElementsByClassName("modal-close");

export const modalOpenClass = "modal-open";

const openModal = function (btn) {
  const matchingModal = [...modals].filter(
    (modal) => modal.id && btn.id.includes(modal.id)
  )[0];

  const matchingOverlay = [...overlays].filter(
    (overlay) =>
      overlay.id &&
      btn.id.includes(overlay.id.substring(overlay.id.indexOf("-") + 1))
  )[0];

  document.body.classList.add(modalOpenClass);
  matchingModal.classList.remove("modal-hidden");
  matchingOverlay.classList.remove("modal-hidden");
};

export const closeModal = () => {
  document.body.classList.remove(modalOpenClass);

  [...modals].forEach((modal) => modal.classList.add("modal-hidden"));
  [...overlays].forEach((overlay) => overlay.classList.add("modal-hidden"));
};

export const setupModal = () => {
  [...openModalBtns].forEach((btn) =>
    btn.addEventListener("click", () => openModal(btn))
  );

  [...closeModalBtns].forEach((btn) =>
    btn.addEventListener("click", closeModal)
  );

  [...overlays].forEach((overlay) =>
    overlay.addEventListener("click", closeModal)
  );
  document.addEventListener("keydown", function (e) {
    if (
      e.key === "Escape" &&
      ![...modals].forEach((modal) => modal.classList.contains("modal-hidden"))
    ) {
      closeModal();
    }
  });
};
